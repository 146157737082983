import { useUserPermissions } from './use-permission';
import {
  NavigateOptions,
  Path as NavigatePath,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { urlReplacer } from '../utils/url-helpers';
import { ROUTES } from '../constants/routes';
import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { isEmbedAtom } from '../../modules/embed/state';

export const useCustomNavigate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const customNavigate = useMemo(() => {
    return (
      navigateTo: number | (Partial<NavigatePath> & { href?: string }),
      navigateOptions?: NavigateOptions
    ): void => {
      if (typeof navigateTo === 'number') {
        navigate(navigateTo);
        return;
      }
      const { pathname, search, hash, href } = navigateTo;
      if (typeof href === 'string' && href.length > 0) {
        navigate(href, navigateOptions);
        return;
      }
      let existingUrlParams = new URLSearchParams(location.search);
      const newUrlParams = new URLSearchParams(search);
      const mergedUrlParams = new URLSearchParams({
        ...Object.fromEntries(existingUrlParams),
        ...Object.fromEntries(newUrlParams),
      }).toString();
      navigate(
        {
          pathname,
          search: mergedUrlParams.toString(),
          hash:
            typeof hash === 'string' && hash.length > 0 ? hash : location.hash,
        },
        navigateOptions
      );
    };
  }, [navigate, location.pathname, location.search, location.hash]);
  return customNavigate;
};

const useConversationNavigate = () => {
  const {
    canReadCustomerPhone: { hasAccess: phoneAccess },
  } = useUserPermissions();
  const location = useLocation();
  const navigate = useNavigate();
  const isEmbed = useAtomValue(isEmbedAtom);
  const navigateToConversation = ({
    phoneNumber,
    chatTypeId,
    navigateOptions,
    waba,
  }: {
    phoneNumber: string;
    chatTypeId?: string;
    navigateOptions?: NavigateOptions;
    waba: string;
  }): void => {
    navigate(
      {
        pathname: urlReplacer(
          `${isEmbed ? ROUTES.EMBED.replace('/*', '') : ''}${
            ROUTES.CONVERSATIONS
          }/:integrationWabaNumber/:id`,
          [
            [':id', !phoneAccess && chatTypeId ? chatTypeId : phoneNumber],
            [':integrationWabaNumber', waba],
          ]
        ),
        search: location.search,
      },
      navigateOptions
    );
  };
  return {
    navigateToConversation,
  };
};

export default useConversationNavigate;
