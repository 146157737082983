import { NavigateOptions, Path as NavigatePath } from 'react-router-dom';
import { ChatRowData } from '../../home/v1/components/chat-list-section/chat-row/typings';
import { CustomFieldsTypes, GroupLevelAccess } from '../../home/v1/typings';
import { Dict as AnalyticsEventOptions } from 'mixpanel-browser';
import { WabaIntegrationMemberStatus } from '../../../shared/typings/waba';
import { FrequencyCappingState } from '../../../shared/typings/frequency-capping';

export enum MiniMessageTypes {
  OPEN_GROUP_CHAT = 'OPEN_GROUP_CHAT',
  OPEN_RENEWAL_BLOCKER = 'OPEN_RENEWAL_BLOCKER',
  GO_BACK = 'GO_BACK',
  CLOSE_IFRAME = 'CLOSE_IFRAME',
  SEND_INDIVIDUAL_MESSAGE = 'SEND_INDIVIDUAL_MESSAGE',
  TRACK_EVENT = 'TRACK_EVENT',
  OPEN_CUSTOMER_CHAT = 'OPEN_CUSTOMER_CHAT',
  OPEN_INTEGRATIONS = 'OPEN_INTEGRATIONS',
  REDIRECT = 'REDIRECT',
  ROUTE_PATH = 'ROUTE_PATH',
  SWITCH_ORG = 'SWITCH_ORG',
  CONTACT_SUPPORT = 'CONTACT_SUPPORT',
  GET_PERMISSIONS = 'GET_PERMISSIONS',
  BILLING_DETAILS_SUBMITTED = 'BILLING_DETAILS_SUBMITTED',
  ADD_GROUP_MEMBERS = 'ADD_GROUP_MEMBERS',
  DELETE_CUSTOM_FIELD = 'DELETE_CUSTOM_FIELD',
  UPDATE_CUSTOM_FIELD = 'UPDATE_CUSTOM_FIELD',
  CREATE_CUSTOM_FIELD = 'CREATE_CUSTOM_FIELD',
  GET_TEMPLATE_DATA = 'GET_TEMPLATE_DATA',
  SEND_CURL_FOR_TEMPLATE_AS_API = 'SEND_CURL_FOR_TEMPLATE_AS_API',
  ADD_BROADCAST_SUCCESS = 'ADD_BROADCAST_SUCCESS',
  GET_SELECTED_CUSTOMER_IDS = 'GET_SELECTED_CUSTOMER_IDS',
  CHANGE_CUSTOM_TOKEN = 'CHANGE_CUSTOM_TOKEN',
  SHOW_MODAL_HEADER = 'SHOW_MODAL_HEADER',
  HIDE_MODAL_HEADER = 'HIDE_MODAL_HEADER',
  GET_CURRENT_PATH = 'GET_CURRENT_PATH',
  OPEN_IMPORT_EXCEL_MODAL = 'OPEN_IMPORT_EXCEL_MODAL',
  EMBED_NAVIGATE_CHAT_LIST = 'EMBED_NAVIGATE_CHAT_LIST',
  OPEN_AUTO_RETRY_SETTINGS_MODAL = 'OPEN_AUTO_RETRY_SETTINGS_MODAL',
  SET_AUTO_RETRY = 'SET_AUTO_RETRY',
  SET_USER_STATUS = 'SET_USER_STATUS',
  SEND_CHANNEL_MEMBERS_INFO = 'SEND_CHANNEL_MEMBERS_INFO',
  LOGOUT = 'LOGOUT',
  OPEN_EXPORT_SUCCESS_MODAL = 'OPEN_EXPORT_SUCCESS_MODAL',
  OPEN_REPORT_DOWNLOAD_SUCCESS_MODAL = 'OPEN_REPORT_DOWNLOAD_SUCCESS_MODAL',
}

export interface OpenGroupChat {
  type: MiniMessageTypes.OPEN_GROUP_CHAT;
  data: {
    groupChatId: string;
    groupId: string;
    groupChatName: string;
    canManageGroupAccess?: boolean;
    groupAccessLevel?: GroupLevelAccess;
  };
}

export interface GoBack {
  type: MiniMessageTypes.GO_BACK;
}

export interface OpenRenewalBlocker {
  type: MiniMessageTypes.OPEN_RENEWAL_BLOCKER;
}

export interface OpenCustomerChat {
  type: MiniMessageTypes.OPEN_CUSTOMER_CHAT;
  data: ChatRowData;
}

export interface Redirect {
  type: MiniMessageTypes.REDIRECT;
  data: {
    url: string;
    state: any;
  };
}

export interface RoutePath {
  type: MiniMessageTypes.ROUTE_PATH;
  data: {
    path: Partial<NavigatePath>;
    options?: NavigateOptions;
  };
}

export interface SendIndividualMessage {
  type: MiniMessageTypes.SEND_INDIVIDUAL_MESSAGE;
  data: {
    customerId: string;
    customerName?: string;
    chatId?: string;
    phoneNumber: string;
    integrationWabaNumber?: string;
    integrationId?: string;
  };
}

export interface TrackEvent {
  type: MiniMessageTypes.TRACK_EVENT;
  data: {
    eventName: string;
    properties?: AnalyticsEventOptions;
  };
}

export interface CloseIframe {
  type: MiniMessageTypes.CLOSE_IFRAME;
}

export interface OpenIntegrations {
  type: MiniMessageTypes.OPEN_INTEGRATIONS;
}

export interface SwitchOrg {
  type: MiniMessageTypes.SWITCH_ORG;
  data: {
    orgId: string;
  };
}

export interface BillingDetailsSubmitted {
  type: MiniMessageTypes.BILLING_DETAILS_SUBMITTED;
  data: {
    isSubmitted: boolean;
  };
}

export interface ContactSupport {
  type: MiniMessageTypes.CONTACT_SUPPORT;
  data: {
    message: string;
  };
}

export interface GetPermissions {
  type: MiniMessageTypes.GET_PERMISSIONS;
  access: {
    permissions: Array<string>;
    role: string;
  };
}

export interface AddGroupMembers {
  type: MiniMessageTypes.ADD_GROUP_MEMBERS;
  data: {
    groupId: string;
    groupChatId: string;
    groupChatName: string;
  };
}

export interface DeleteCustomField {
  type: MiniMessageTypes.DELETE_CUSTOM_FIELD;
  data: {
    id: string;
    type?: CustomFieldsTypes;
  };
}

export interface UpdateCustomField {
  type: MiniMessageTypes.UPDATE_CUSTOM_FIELD;
  data: {
    id: string;
    type: CustomFieldsTypes;
  };
}

export interface CreateCustomField {
  type: MiniMessageTypes.CREATE_CUSTOM_FIELD;
  data: {
    id: string;
    type: CustomFieldsTypes;
  };
}

export interface GetTemplateData {
  type: MiniMessageTypes.GET_TEMPLATE_DATA;
  data: {
    id: string;
  };
}

export interface SendTemplateCurl {
  type: MiniMessageTypes.SEND_CURL_FOR_TEMPLATE_AS_API;
  data: {
    curl: string;
  };
}

export interface AddToBroadcast {
  type: MiniMessageTypes.ADD_BROADCAST_SUCCESS;
  data: {};
}

export interface GetSelectedCustomerIds {
  type: MiniMessageTypes.GET_SELECTED_CUSTOMER_IDS;
  data: {};
}

export interface ChangeCustomToken {
  type: MiniMessageTypes.CHANGE_CUSTOM_TOKEN;
  data: {
    token: string;
    iframeId: string;
  };
}

export interface ShowBotModalHeader {
  type: MiniMessageTypes.SHOW_MODAL_HEADER;
  data: {
    title: string;
  };
}

export interface HideBotModalHeader {
  type: MiniMessageTypes.HIDE_MODAL_HEADER;
  data: {
    title: string;
  };
}

export interface GetCurrentPath {
  type: MiniMessageTypes.GET_CURRENT_PATH;
  data: {
    iframeId: string;
  };
}

export interface OpenImportExcelModal {
  type: MiniMessageTypes.OPEN_IMPORT_EXCEL_MODAL;
  data: {};
}

export interface EmbedNavigateChatList {
  type: MiniMessageTypes.EMBED_NAVIGATE_CHAT_LIST;
  data: {
    phoneNumber: string;
    integrationWabaNumber: string;
  };
}

export interface OpenAutoRetrySettingsModal {
  type: MiniMessageTypes.OPEN_AUTO_RETRY_SETTINGS_MODAL;
  data: {
    broadcastId: string;
  };
}

export interface SetAutoRetry {
  type: MiniMessageTypes.SET_AUTO_RETRY;
  data: {
    broadcastId: string;
    state: FrequencyCappingState;
  };
}

export interface SetUserStatus {
  type: MiniMessageTypes.SET_USER_STATUS;
  data: {
    status: WabaIntegrationMemberStatus | null;
    statusExpiration: string | null;
  };
}

export interface SendChannelMembersInfo {
  type: MiniMessageTypes.SEND_CHANNEL_MEMBERS_INFO;
  data: {
    groupId: string;
    memberCount: number;
    showMemberCount: boolean;
  };
}

export interface Logout {
  type: MiniMessageTypes.LOGOUT;
}

export interface SetUserStatus {
  type: MiniMessageTypes.SET_USER_STATUS;
  data: {
    status: WabaIntegrationMemberStatus | null;
    statusExpiration: string | null;
  };
}

export interface OpenExportSuccessModal {
  type: MiniMessageTypes.OPEN_EXPORT_SUCCESS_MODAL;
  data: {
    email?: string | null;
    phone?: string | null;
  };
}

export interface OpenReportDownloadSuccessModal {
  type: MiniMessageTypes.OPEN_REPORT_DOWNLOAD_SUCCESS_MODAL;
  data: {
    email?: string | null;
  };
}

export type MiniMessage =
  | GoBack
  | OpenGroupChat
  | OpenRenewalBlocker
  | CloseIframe
  | SendIndividualMessage
  | TrackEvent
  | OpenCustomerChat
  | OpenIntegrations
  | Redirect
  | RoutePath
  | ContactSupport
  | GetPermissions
  | SwitchOrg
  | BillingDetailsSubmitted
  | DeleteCustomField
  | UpdateCustomField
  | CreateCustomField
  | AddGroupMembers
  | GetTemplateData
  | SendTemplateCurl
  | AddToBroadcast
  | GetSelectedCustomerIds
  | ChangeCustomToken
  | ShowBotModalHeader
  | HideBotModalHeader
  | GetCurrentPath
  | OpenImportExcelModal
  | EmbedNavigateChatList
  | SetUserStatus
  | SendChannelMembersInfo
  | Logout
  | OpenAutoRetrySettingsModal
  | SetAutoRetry
  | OpenExportSuccessModal
  | OpenReportDownloadSuccessModal;

export interface GetEnterpriseAnalyticsEmbedLinkResponse {
  title?: string;
  embedLink?: string;
  replaceAnalyticsWithEnterpriseAnalytics?: boolean;
  additionalTitle?: string;
  additionalEmbedLink?: string;
}
