import { atom } from 'jotai';

import {
  createRequestAtom,
  createPaginatedRequestAtom,
} from '../../../shared/utils/request-atom';

import {
  BROADCAST_GROUP_NAME_TYPES,
  ChannelsPaginationParams,
  CustomerSegment,
  CustomerSegmentFilterContact,
  GetCustomerSegment,
  GetCustomerSegmentsMetaData,
  GetCustomerSegmentsPaginationData,
  ImportExcelFileStatus,
  MemberChannels,
  Selections,
  IMPORT_BROADCAST_TYPE,
  ChannelsUpdates,
} from '../typings';
import { CustomerChatMeta } from '../../home/v1/typings';
import {
  Channel,
  ChannelWithId,
} from '../../../shared/typings/channel-management';

export const customerSegmentsMetaDataAtom =
  createRequestAtom<GetCustomerSegmentsMetaData>();

export const getAllCustomerSegmentsAtom = createPaginatedRequestAtom<
  CustomerSegment,
  GetCustomerSegmentsPaginationData
>({ loading: false });

export const getCustomerSegmentAtom = createRequestAtom<
  GetCustomerSegment | undefined
>();

export const activeCustomerSegmentAtom = atom<CustomerSegment | null>(null);

export const getAllCustomerSegmentContactsAtom = createPaginatedRequestAtom<
  CustomerSegmentFilterContact,
  GetCustomerSegmentsPaginationData
>({ loading: false });

export const importExcelFilesStatusAtom = atom<ImportExcelFileStatus[] | null>(
  null
);

export const importCustomersNotificationAtom = atom<boolean>(false);

export const isExcelFileUploadLoadingAtom = atom<boolean>(false);

export const importCustomersModalVisibleAtom = atom<boolean>(false);

export const excelUploadStatusModalVisibleAtom = atom<boolean>(false);

export const selectedCustomerIdAtom = atom<string | null>(null);

export const memberChannelsListRequestAtom =
  createRequestAtom<MemberChannels>();

export const selectedBroadcastChannelsAtom = atom<Selections<string, string>>(
  {}
);

export const channelSelectionsAtom = atom(
  (get) => get(selectedBroadcastChannelsAtom),
  (
    get,
    set,
    { groupId, clearAll, selections: newSelections, groupName }: ChannelsUpdates
  ) => {
    if (clearAll) {
      set(selectedBroadcastChannelsAtom, {});
      return;
    }

    if (newSelections) {
      set(selectedBroadcastChannelsAtom, newSelections);
      return;
    }

    if (!groupId) {
      return;
    }

    const selections = { ...get(selectedBroadcastChannelsAtom) };

    if (selections[groupId]) {
      delete selections[groupId];
    } else {
      if (!groupName) return;
      selections[groupId] = groupName;
    }

    set(selectedBroadcastChannelsAtom, selections);
  }
);

export const channelsPaginationAtom = createPaginatedRequestAtom<
  ChannelWithId,
  ChannelsPaginationParams
>({ loading: false });

export const selectedBroadcastDetailsAtom = atom<
  Map<
    string,
    {
      name: string;
      type: IMPORT_BROADCAST_TYPE;
      broadcastGroupNameType: BROADCAST_GROUP_NAME_TYPES;
    }
  >
>(new Map());

export const newListsAtom = atom<Channel[]>([]);

export const selectedCustomerIdForChatAtom = atom<string | null>(null);

export const customerChatPanelWidthAtom = atom<number>(450);
