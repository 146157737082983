import { useState, useEffect } from 'react';
import { EmbedSource } from '../../modules/embed/typings';

const useZohoSDK = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const embedSource = searchParams.get('embedSource');

    // Only load SDK if embedSource is ZOHO_CRM_WIDGET
    if (embedSource !== EmbedSource.ZOHO_CRM_WIDGET) {
      return;
    }

    if (window.ZOHO) {
      setIsLoaded(true);
      return;
    }

    const script = document.createElement('script');
    script.src = 'https://live.zwidgets.com/js-sdk/1.2/ZohoEmbededAppSDK.min.js';
    script.async = true;
    script.onload = () => {
      setIsLoaded(true);
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return isLoaded;
};

export default useZohoSDK;
