import { FC, SVGProps } from 'react';

const MetaIconSmall: FC<SVGProps<SVGSVGElement> & { size?: number }> = ({
  color = '#2d2d2d',
  size,
  ...props
}) => {
  return (
    <svg
      width="12"
      height="12"
      {...props}
      viewBox="0 0 22 18"
      fill="#2d2d2d"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.59229 10.5127C2.59229 11.4291 2.79343 12.1327 3.05634 12.5584C3.40103 13.1159 3.91515 13.3521 4.43928 13.3521C5.11532 13.3521 5.73376 13.1843 6.92558 11.536C7.88037 10.2148 9.00542 8.36027 9.76241 7.19766L11.0444 5.22799C11.9349 3.86006 12.9656 2.33941 14.1474 1.30867C15.1123 0.467381 16.153 0 17.2004 0C18.959 0 20.634 1.01906 21.916 2.93031C23.319 5.02351 24 7.66004 24 10.3809C24 11.9983 23.6812 13.1868 23.1387 14.1257C22.6146 15.0338 21.593 15.941 19.8745 15.941V13.3521C21.3459 13.3521 21.7132 12 21.7132 10.4526C21.7132 8.2476 21.1991 5.80053 20.0665 4.05202C19.2628 2.8118 18.2212 2.05397 17.0753 2.05397C15.8359 2.05397 14.8385 2.98873 13.7176 4.65545C13.1217 5.54097 12.5099 6.62011 11.8231 7.83781L11.0669 9.17735C9.54792 11.8706 9.16317 12.4841 8.40367 13.4965C7.07247 15.2692 5.93574 15.941 4.43928 15.941C2.66407 15.941 1.54152 15.1723 0.846293 14.0139C0.278759 13.07 0 11.8314 0 10.4201L2.59229 10.5127Z"
        fill="#2d2d2d"
      />
      <path
        d="M2.04297 3.11309C3.23145 1.28112 4.94657 0 6.91374 0C8.05298 0 9.18555 0.337182 10.3682 1.30282C11.6618 2.3586 13.0406 4.09709 14.7607 6.9623L15.3775 7.99054C16.8664 10.471 17.7136 11.7471 18.2093 12.3489C18.847 13.1217 19.2935 13.3521 19.8735 13.3521C21.345 13.3521 21.7122 12 21.7122 10.4526L23.999 10.3809C23.999 11.9983 23.6802 13.1868 23.1377 14.1257C22.6136 15.0338 21.592 15.941 19.8735 15.941C18.8052 15.941 17.8588 15.709 16.8122 14.7217C16.0076 13.9638 15.067 12.6176 14.3434 11.4074L12.191 7.81194C11.111 6.00751 10.1203 4.66212 9.54693 4.05286C8.93016 3.39769 8.13728 2.60648 6.87201 2.60648C5.84795 2.60648 4.97829 3.32508 4.25051 4.42426L2.04297 3.11309Z"
        fill="#2d2d2d"
      />
      <path
        d="M6.873 2.60648C5.84894 2.60648 4.97927 3.32508 4.2515 4.42426C3.22242 5.97747 2.59229 8.291 2.59229 10.5127C2.59229 11.4291 2.79343 12.1327 3.05634 12.5584L0.846293 14.0139C0.278759 13.07 0 11.8314 0 10.4201C0 7.85367 0.70441 5.17875 2.04396 3.11309C3.23244 1.28112 4.94756 0 6.91473 0L6.873 2.60648Z"
        fill="#2d2d2d"
      />
    </svg>
  );
};

export default MetaIconSmall;
