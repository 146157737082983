const clearAllNotifications = async () => {
  if ('Notification' in window && 'serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.ready;
      const notifications = await registration.getNotifications();
      notifications.forEach((notification) => notification.close());
    } catch (error) {
      console.log('Error clearing notifications:', error);
    }
  }
};

export default clearAllNotifications;
