import { ComponentType, LazyExoticComponent, Suspense } from 'react';
import Loader from '../../../../../../shared/components/molecules/loader';
import { LOADING } from '../../../../../../shared/constants';
import ProtectedPlaceholder from '../../protected';
import { useProfile } from '../../../../../../shared/hooks/use-profile';

export const ProtectedRoute = ({
  component: LazyComponent,
  hasAccess,
}: {
  component: LazyExoticComponent<ComponentType<any>>;
  hasAccess: boolean;
}) => {
  const userDetails = useProfile();

  if (hasAccess) {
    // if (hasAccess && userDetails?.orgId === 'org_w4wEYx0xZ7') {
    return (
      <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
        <LazyComponent />
      </Suspense>
    );
  }

  return <ProtectedPlaceholder />;
};
