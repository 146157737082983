import { UserCredential } from 'firebase/auth';
import { EmbedSource } from '../typings';
import { User } from '../../../shared/typings/request';
import { zohoChatDetails } from '../../login/v1/requests/embed-login';
import { NavigateOptions, Path } from 'react-router-dom';
import { ROUTES } from '../../../shared/constants/routes';
import { Notification } from '../../../shared/typings/notification';

interface EmbedLoginSuccessData {
  firebaseAuthResponse: UserCredential;
  firebaseUser: User;
  orgId: string;
}

export const redirectToLead = async (
  leadId: string,
  navigate: (
    navigateTo:
      | number
      | (Partial<Path> & {
          href?: string;
        }),
    navigateOptions?: NavigateOptions
  ) => void,
  addNotification: (
    arg: Partial<Notification> & {
      message: Notification['message'];
    }
  ) => void | Promise<void>
) => {
  try {
    if (!leadId) {
      throw new Error('Failed to get Zoho record details');
    }
    const chatDetails = await zohoChatDetails({
      moduleApiName: 'Leads',
      zohoCustomerId: leadId,
    });
    if (!chatDetails?.phoneNumber || !chatDetails?.wabaNumber) {
      throw new Error('Failed to get chat details');
    }
    const pathname = `/embed/conversations/${chatDetails.wabaNumber}/${chatDetails.phoneNumber}`;
    navigate({
      pathname,
    });
  } catch (error) {
    console.error('Error redirecting to lead:', error);
    addNotification({
      message: (error as Error).message || 'Failed to redirect to lead',
      type: 'error',
    });
    navigate({
      pathname: ROUTES.EMBED_CONVERSATIONS,
    });
  }
};

export const emitEmbedLoginSuccess = (data: EmbedLoginSuccessData) => {
  try {
    const embed = new URLSearchParams(window.location.search).get('embed') === 'true';
    const embedSource = new URLSearchParams(window.location.search).get('embedSource');

    if (embed && embedSource === EmbedSource.ZOHO_CRM) {
      const message = {
        type: 'EMBED_LOGIN_SUCCESS',
        ...JSON.parse(
          JSON.stringify({
            ...data.firebaseAuthResponse,
            firebaseUser: data.firebaseUser,
            orgId: data.orgId,
          })
        ),
      };

      // Send message to parent window
      window.parent.postMessage(message, '*', []);

      // Send message to opener window if it exists
      if (window.opener && typeof window.opener.postMessage === 'function') {
        window.opener.postMessage(message, '*', []);
      }

      // Handle window closing
      if (window.opener && typeof window.close === 'function') {
        // NOTE: not closing the window immediately so that the message event listener will receive the event and process it
        // In some situations closing the window immediately disconnects the postMessage event listener in the opener window
        setTimeout(() => {
          window.close();
        }, 0);
      }
    }
  } catch (error) {
    // Log error but don't throw to prevent disrupting main flow
    console.error('Error in emitEmbedLoginSuccess:', error);
  }
};
