import { FC, SVGProps } from 'react';

const AIBotIcon: FC<SVGProps<SVGSVGElement>> = ({ color = '#2d2d2d', ...props }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props} fill="none">
      <path
        d="M17.5 5.63805C17.5 4.26199 16.3807 3.14648 15 3.14648H5C3.61929 3.14648 2.5 4.26199 2.5 5.63805V16.0215C2.5 16.6903 3.25258 17.0848 3.80549 16.7059L6.2985 14.9974C6.50688 14.8546 6.75384 14.7781 7.00674 14.7781H15C16.3807 14.7781 17.5 13.6626 17.5 12.2866V5.63805Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.37786 10.4702L8.29371 10.2424C7.97633 9.3834 7.30006 8.70607 6.44229 8.38824L6.21484 8.30395L6.44229 8.21965C7.30006 7.9018 7.97633 7.22453 8.29371 6.36553L8.37786 6.1377L8.46211 6.36553C8.77944 7.22453 9.45569 7.9018 10.3135 8.21965L10.541 8.30395L10.3135 8.38824C9.45569 8.70607 8.77944 9.3834 8.46211 10.2424L8.37786 10.4702Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.8047 11.5051C12.6617 11.0345 12.2941 10.6663 11.8242 10.523C12.2941 10.3798 12.6617 10.0115 12.8047 9.54102C12.9478 10.0115 13.3155 10.3798 13.7853 10.523C13.3155 10.6663 12.9478 11.0345 12.8047 11.5051Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default AIBotIcon;
