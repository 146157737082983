import {
  deleteMessagingToken,
  firebaseAuth,
} from '../../../../infra/firebase/init';
import { trackLogout } from '../../../../infra/analytics/utils';
import { logout } from '../requests/logout';
import cleanLocalStorageUseCase from './clean-localstorage-use-case';
import clearAllNotifications from './clear-notifications';

export const logoutUseCase = async () => {
  try {
    await Promise.all([
      logout(),
      deleteMessagingToken(),
      clearAllNotifications(),
    ]);
    cleanLocalStorageUseCase();
  } catch (error) {
    console.log('error in logout', error); // we want to ignore this error and proceed with logout
  }
  await firebaseAuth.signOut();
  trackLogout();
};
