import { get, post } from '../../../../infra/rest';
import { EmbedAutoLoginRequest, EmbedAutoLoginResponse, EmbedClient } from '../typings';

export const embedLoginRequest = async (data: EmbedAutoLoginRequest) => {
  const apiEndpoint =
    data.embedClient && data.embedClient === EmbedClient.SALESFORCE
      ? '/v1/salesforce/authenticate'
      : '/v1/hubspot/embed/authenticate';
  return post<EmbedAutoLoginResponse>(
    apiEndpoint,
    {
      body: JSON.stringify(data),
    },
    true
  );
};

export const zohoChatDetails = async (data: { moduleApiName: string; zohoCustomerId: string }) => {
  return post<{
    phoneNumber: string;
    wabaNumber: string;
  }>('/v1/zoho-crm/chat-details', { body: JSON.stringify(data) }, true);
};
